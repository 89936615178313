import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { CTA, CTA_NAMES } from "../components/CTAs"
import {
  FirstSection,
  FocusSection,
  BoxesSection,
  SecurityFeaturesSection,
} from "../components/Sections"
import { Typography, Box } from "@material-ui/core"
import { BasicContentBox } from "../components/ContentBoxes"
import SectionSeparator from "../components/SectionSeparator"
import SectionContainer from "../components/SectionContainer"

const ICON_NAMES = {
  BACKUPS: "backups",
  AUTH: "auth",
  GLOBE: "globe",
  GRAPH: "graph",
  ISOLATION: "isolation",
  MULTISIG: "multisig",
  RECOVERY: "recovery",
  SEGREGATION: "segregation",
  SM: "sm",
  AIR_GAP: "air-gap",
}

const SECURITY_FEATURES = [
  {
    title: "Cold Key Storage",
    preview:
      "The key lifecycle is entirely air-gapped to minimize the attack surface.",
    iconName: ICON_NAMES.AIR_GAP,
    content: [
      {
        type: "paragraph",
        data:
          "We use a proprietary hardware security module, the Knox Security Module, or Ø-SM, which was designed in-house. The Ø-SM has successfully passed a third-party security assessment that addressed both the security of its implementation and the security of procedures surrounding storage and usage. All computers that interface with these Ø-SMs, throughout their entire lifecycle, are air-gapped – quarantined from any network connection.",
      },
    ],
  },
  {
    title: "Transaction Signing",
    preview: "Customers must initialize all transactions to be processed.",
    iconName: ICON_NAMES.SM,
    content: [
      {
        type: "paragraph",
        data:
          "Wallet keys are stored on a Ø-SM that does not allow retrieving the keys. Retrieving the keys from the Ø-SM would be extremely difficult, even with direct physical access to the Ø-SM. The Ø-SM is further vaulted, while no single physical location has signing authority over a customer account. Knox has no power to sign a transaction with any wallet key unless the customer has sent us an authorizing signature on said transaction.",
      },
    ],
  },
  {
    title: "Entropy & Client Account Segregation",
    preview:
      "Client accounts are segregated at the root entropy level. No shared omnibus.",
    iconName: ICON_NAMES.SEGREGATION,
    content: [
      {
        type: "paragraph",
        data:
          "We use Bitcoin multisig in a 3-of-4 scheme. Each of the 3 root keys are stored on an independent Ø-SM, vaulted, and used in a physical location distinct from the rest of the multisignature Ø-SM quorum. Each customer has their own set of root wallet extended keys in a 3-of-4 multisig scheme backed by 3 Ø-SMs. A customer can have any number of sub-accounts, each containing any number of wallets. Wallet keys are derived from the customer’s root keys using BIP32.",
      },
    ],
  },
  {
    title: "Strict Process Isolation",
    preview:
      "All servers and customer terminals boot into a secure environment.",
    iconName: ICON_NAMES.ISOLATION,
    content: [
      {
        type: "paragraph",
        data:
          "The initial Knox OS image is signed and must verify against keys loaded into firmware before boot. For servers, we flash and validate the BIOS and IPMI firmware and install our own secure boot keys before deployment. Secure boot private keys and software signing keys are Ø-SM-backed. Filesystems containing any software – both the Knox OS and the application software – do not persist changes across reboot; the filesystem images containing software are signed and must verify against public keys embedded in the initial Knox OS image. We use virtualization, kernel namespaces, and process isolation to help ensure that malicious access to one service cannot be escalated to other services on the same physical machine.",
      },
    ],
  },

  {
    title: "Partitioning & Physical Segregation",
    preview:
      "Each root key in a customer’s multisig scheme is handled by a distinct physical device in a distinct data center behind a distinct firewall.",
    iconName: ICON_NAMES.GLOBE,
    content: [
      {
        type: "paragraph",
        data:
          "We accomplish partitioning in each of these security-critical areas as follows:",
      },
      {
        type: "list",
        data: [
          "Knox Terminal: each customer has a distinct Ø-Terminal that handles their account only. Code integrity is protected with strict process isolation (described previously). Customers are instructed to shut down the machine across sessions to destroy any possible malicious state. This is enforced by a watchdog timer. The terminal’s firmware, kernel, and user space are hardened against attaching any unauthorized device. The level of security provided by the customer Ø-Terminal has been assessed by a third party.",
          "Offline machine for transaction signing: The machine is rebooted between signing each transaction, leaving no state behind.",
          "Knox Secure Modules: each customer root key is on its own Ø-SM. The HSM verifies the customer’s intent by checking a signature issued on the customer terminal over the transaction sighash and other details against the public key of a Ø-SM-backed key pair held by the customer.",
        ],
      },
    ],
  },
  {
    title: "Data Storage & Encryption",
    preview:
      "Most sensitive secrets are stored on Knox Secure Modules and cannot be retrieved.",
    iconName: ICON_NAMES.GRAPH,
    content: [
      {
        type: "paragraph",
        data:
          "We use Ø-SMs as our primary tool to protect sensitive data (this includes TLS keys, code signing keys, CAs, wallet keys, and customer authorization keys). Cryptographic operations depending on a secret are performed by the Ø-SM that contains it. Access controls are enforced by Ø-SMs wherever possible. We use an in-house PKI that resembles x509 but with multisig support and complex policy enforcement. Highly privileged operations (e.g. certificate issue, root key rotation) are protected by a multisig scheme over many HSM-backed key pairs.",
      },
    ],
  },
  {
    title: "Client Account Backups",
    preview:
      "Encrypted wallet key backups are using Shamir’s Secret Sharing Scheme.",
    iconName: ICON_NAMES.BACKUPS,
    content: [
      {
        type: "paragraph",
        data:
          "For each key, shares in a 2-of-4 configuration are printed on paper and archived in independent vaults. Shares are held in 4 different cities such that complete compromise of all backup material in any one city by a malicious party will not result in disclosure of any keys. On the other hand, destruction of backups at any 2 facilities will not result in key loss. Secure transport and storage is provided by a globally-recognized secure logistics firm.",
      },
    ],
  },
  {
    title: "Key Backup Storage & Retrieval",
    preview:
      "A security and logistics firm is responsible for storage and retrieval of backups.",
    iconName: ICON_NAMES.RECOVERY,
    content: [
      {
        type: "paragraph",
        data:
          "The security and logistics firm requires the customer to authorize Knox to access backups of their keys.",
      },
      {
        type: "list",
        data: [
          "After access is granted, Knox software is used to reconstruct the missing private keys from the backup shares, which are then flashed onto replacement Ø-SMs.",
          "The replacement Ø-SMs are shipped to data centers (transaction processing facilities) where they are securely stored in an offline vault.",
          "The customer then authorizes the transactions necessary to flush all funds into a new set of wallets backed by fresh keys.",
          "Some time after all funds have been flushed, the old wallet keys are destroyed.",
        ],
      },
    ],
  },
  {
    title: "Client Authentication",
    preview:
      "All Knox clients are required to use three-factor authentication.",
    iconName: ICON_NAMES.AUTH,
    content: [
      {
        type: "paragraph",
        data: "The three-factor authentication requires the following:",
      },
      {
        type: "list",
        data: [
          "A user-generated password",
          "Private keys on a TPM in the customer’s Ø-Terminal that authenticate the Ø-Terminal with our backend. Withdrawal requests can be authorized only from this Ø-Terminal.",
          "Private keys on an access key that the customer keeps separate from the Ø-Terminal when not in use.",
        ],
      },
      {
        type: "paragraph",
        data:
          "Customers receive access keys on one or more HSMs. We use Yubikeys operating as PIV smart cards; keys are generated on the card and are not backed up. The customer is responsible for physically securing this access key.",
      },
      {
        type: "paragraph",
        data:
          "Public keys are stored in a database that is logically replicated across all data centers. Replication must be authorized —     Inserts to the keys table require multisig sign-off that is verified independently at each center. Updates to existing key entries are not permitted. Signatures are validated against each replica as the corresponding account operation (withdrawal, policy change) passes through the data center.",
      },
      {
        type: "paragraph",
        data:
          "Public keys are stored in protected flash memory of the Ø-SM that carries the wallet keys. This Ø-SM will only issue a signature from the wallet key if the withdrawal request has been signed by the appropriate customer access key.",
      },
    ],
  },
]

const Security = () => (
  <Layout>
    <SEO title="Security" />
    <FirstSection
      image="Cieling1"
      h1="Security by Paranoids"
      boxHeader="But there's no such thing as ‘perfect security’"
    >
      <Typography variant="body1">
        Correct software and specialized hardware security are essential to
        managing Bitcoin keys in offline environments. Knox built a truly
        air-gapped operational model minimizing the attack surface of the entire
        key lifecycle with geographic separation and redundancies.
      </Typography>
    </FirstSection>

    <FocusSection
      title="Our Staff is Locked Out"
      paragraphs={[
        "No single employee or group of employees at Knox can arbitrarily move the funds we custody. We are locked out of our own custody system—ensuring the security of our employees and the holdings of our clients. All requests are authorized by clients and guaranteed by machines.",
      ]}
      cta={<CTA name={CTA_NAMES.custody} />}
    />

    <SectionSeparator title="Security features" />

    <SectionContainer>
      <SecurityFeaturesSection features={SECURITY_FEATURES} />
    </SectionContainer>

    <BoxesSection
      boxes={[
        {
          content: (
            <BasicContentBox header="Additional Details Available">
              <>
                <Typography variant="body1">
                  Extra information on wallet policy construction, validation,
                  transaction flow, activity logs, API specs, whitelisting
                  procedures and other security practices are available on
                  demand. We would be happy to have a call to discuss further.
                  Please email security@knoxcustody.com.
                </Typography>
                <Box mt={4}>
                  <CTA name={CTA_NAMES.contact} />
                </Box>
              </>
            </BasicContentBox>
          ),
          margin: { xs: { bottom: "150px" } },
          maxWidth: "500px",
        },
      ]}
      image={{ name: "Cieling2" }}
    />

    <FocusSection
      paragraphs={[
        "If you want to learn more about our threat model and security, we’d be pleased to give you more details about Knox Custody accounts.",
      ]}
      align="center"
      cta={<CTA name={CTA_NAMES.scheduleDemo} />}
    />
  </Layout>
)

export default Security
